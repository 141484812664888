import React from "react";
import "./Prices.css";

const Prices = () => {
  return (
    <section id="prices" className="prices">
      <h2>Our Prices</h2>
      <p>Competitive pricing for all your transportation needs.</p>
      <p>Call now at 510-234-7000 to get an estimate</p>
    </section>
  );
};

export default Prices;
