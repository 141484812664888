import React from "react";
import "./AboutUs.css";

const AboutUs = () => {
  return (
    <section id="aboutus" className="aboutus">
      <h2>About Us</h2>
      <p>
        We are a dedicated team of professionals providing top-notch taxi
        services.
      </p>
      <p>Over 20+ years in the business</p>
    </section>
  );
};

export default AboutUs;
