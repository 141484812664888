import React from "react";
import "./Navbar.css";

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="contact-number">
        <a href="tel:5102347000">Call Us: 510-234-7000</a>
      </div>
      <ul>
        <li>
          <a href="#home">Home</a>
        </li>
        <li>
          <a href="#services">Services</a>
        </li>
        <li>
          <a href="#aboutus">About Us</a>
        </li>
        <li>
          <a href="#prices">Prices</a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
