import React from "react";
import "./Services.css";
import MapComponent from "./MapComponent"; // Adjust the path according to your directory structure

const Services = () => {
  return (
    <section id="services" className="services">
      <h2>Our Services</h2>
      <p>
        We offer a variety of services including airport transfers, city tours,
        and more.
      </p>
      <div className="map-container">
        <MapComponent />
      </div>
      <div className="service-locations">
        <h3>Places We Provide Services To:</h3>
        <ul>
          <li>El Cerrito</li>
          <li>Richmond</li>
          <li>San Pablo</li>
          <li>Pinole</li>
          <li>El Sobrante</li>
          <li>Hercules</li>
        </ul>
      </div>
    </section>
  );
};

export default Services;
