import React from "react";
import backgroundImg from "./assets/taxi.png"; // Adjust the path according to your directory structure
import "./Home.css";

const Home = () => {
  return (
    <section
      id="home"
      className="home"
      style={{ backgroundImage: `url(${backgroundImg})` }}
    >
      <h1>Welcome to Richmond Taxi Service</h1>
      <p>Safe and reliable taxi services in your area.</p>
    </section>
  );
};

export default Home;
