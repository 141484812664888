import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Fix default icon issue with react-leaflet
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

const MapComponent = () => {
  const locations = [
    { name: "El Cerrito", position: [37.9165, -122.3116] },
    { name: "Richmond", position: [37.9358, -122.3478] },
    { name: "San Pablo", position: [37.9621, -122.3455] },
    { name: "Pinole", position: [38.0044, -122.2989] },
    { name: "El Sobrante", position: [37.9771, -122.294] },
  ];

  return (
    <MapContainer
      center={[37.9358, -122.3478]}
      zoom={12}
      style={{ height: "350px", width: "100%" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      {locations.map((location, idx) => (
        <Marker key={idx} position={location.position}>
          <Popup>{location.name}</Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default MapComponent;
